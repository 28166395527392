import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import React from 'react';

import { ImageFaqPanel } from '@src/cms-components';
import { LivePreviewSelect } from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import { useCallback, useEffect, useState } from 'react';
import { BackgroundType } from '@src/shared/interfaces/theme.interface';

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
];

const ImageFaqPanelPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});
  const [secColor, setSecColor] = useState('light' as BackgroundType);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'image_faq_panel',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Secondary Color"
          options={color_options}
          setSelectedItem={setSecColor}
        />
      </LivePreviewHeader>
      {!!livePreviewData && (
        <ImageFaqPanel
          {...{
            background_color: secColor,
            image_faq_panel: [
              {
                panel_image: livePreviewData.panel_image,
                accordion: [],
                uid: livePreviewData.uid,
              },
            ],
          }}
        />
      )}
    </>
  );
};

export default ImageFaqPanelPreviewPage;
