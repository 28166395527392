import React from 'react';

export const LivePreviewSelect = ({
  title,
  options,
  setSelectedItem,
  defaultValue = 'default',
}: any) => {
  return (
    <div>
      <p>{title}</p>
      <select
        onChange={(e) => setSelectedItem(e.currentTarget.value)}
        defaultValue={defaultValue}
      >
        {options?.map(({ label, value }: any, index: number) => (
          <option key={`${value}-${index}`} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const LivePreviewToggle = ({ label, toggleItem }: any) => {
  return (
    <div>
      <label htmlFor="">
        <input
          type="checkbox"
          onChange={(e) => toggleItem(e.currentTarget.checked)}
          defaultChecked={true}
        />
        {label}
      </label>
    </div>
  );
};
