import styled from '@emotion/styled';

export const LivePreviewHeader = styled.div`
  display: flex;
  margin: 0 auto;
  column-gap: 20px;
  justify-content: center;
  background: #eee;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  align-items: flex-end;
`;
